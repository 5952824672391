import {
    Formik,
    FormikProps
} from 'formik';

import {FormComponent} from './FormComponent';


import {ValidationSchema} from './ValidationSchema';
import {
    FormWithActionBar,
    PacFormikComponentState
} from "@pac/platform-ui-components";
import {Credentials} from "../../credentials/types.ts";

export const ConfirmForm = ({
                                record,
                                handleSubmit
                            }: {
    record: Credentials,
    handleSubmit: (values: Credentials) => void
}) => {
    const onSubmit = (values: object) => {
        return handleSubmit(values);
    };

    const renderForm = (props: FormikProps<any>) => {
        return (
            <FormWithActionBar handleSubmit={props.handleSubmit}>
                <FormComponent/>
                <PacFormikComponentState enabled={false} {...props} />
            </FormWithActionBar>
        );
    };

    let initialValues = {} as Credentials;

    if (record) {
        initialValues = record;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={onSubmit}
        >
            {renderForm}
        </Formik>
    );
};
