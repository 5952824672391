import {Field} from 'formik';


import {
    Box,
    SimpleGrid
} from '@mantine/core';
import {PacFormikPasswordInput} from "@pac/platform-ui-components";


export const FormComponent = () => {
    return (
        <SimpleGrid>
            <Box>
                <Field
                    name="otc"
                    placeholder="One Time Code (OTC)"
                    component={PacFormikPasswordInput}
                />
            </Box>

            <Box>
                <Field
                    name="new_password"
                    placeholder="New Password"
                    component={PacFormikPasswordInput}
                />
            </Box>

            <Box>
                <Field
                    name="confirm_password"
                    placeholder="Confirm Password"
                    component={PacFormikPasswordInput}
                />
            </Box>
        </SimpleGrid>
    );
};


