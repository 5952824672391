import {I18n} from '@aws-amplify/core';

import {
    ActionIcon,
    Grid,
    SimpleGrid,
    Stack,
    Title
} from '@mantine/core';

import {ApplicationsList} from './ApplicationsList';

import {IconContext} from 'react-icons';
import {MdSwapVert} from 'react-icons/md';
import {Application} from "../types.ts";


const SwapPositionsButton = ({onClick}: { onClick: () => void }) => {
    const iconContext = {
        style: {
            width: `24px`,
            height: `24px`,
        },
    };

    return (
        <ActionIcon
            title="Swap Positions"
            // icon={<Image src={SwapIcon} alt="Swap" />}
            onClick={onClick}
        >
            <IconContext.Provider value={iconContext}>
                <MdSwapVert/>
            </IconContext.Provider>
        </ActionIcon>

    );
};

export const ReturnToSection = ({
                                    applications,
                                    swapPositions,
                                }: {
    applications: Application[];
    swapPositions: () => void;
}) => {


    if (Array.isArray(applications) && applications.length === 0) {
        return null;
    }


    const enableList = false

    return (
        <SimpleGrid>
            <Title mt="lg" order={4}>
                {I18n.get('Return to')}:
            </Title>

            {enableList && applications.length > 1 ? (
                <Grid>
                    <Grid.Col xs={2} sm={1}>
                        <Stack
                            align="center"
                            justify="center"
                            style={{height: '100%'}}
                        >
                            <SwapPositionsButton onClick={swapPositions}/>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col xs={10} sm={11}>
                        <ApplicationsList
                            applications={applications}
                        />
                    </Grid.Col>
                </Grid>
            ) : (
                <ApplicationsList
                    applications={applications}
                />
            )}


        </SimpleGrid>
    );
};