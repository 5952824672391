import {Field} from 'formik';


import {
    Grid,
    ScrollArea,
    Tabs
} from '@mantine/core';


import {SSOCustomerAgreement} from '../components/SSOCustomerAgreement';
import {TermsOfUseDocument} from '../components/TermsOfUseDocument';
import {PacFormikCheckbox} from "@pac/platform-ui-components";

const agreements = [
    {
        id: 'sso',
        title: 'SSO (Single Sign On) Agreement',
        path: '/some-path',
    },
    {
        id: 'terms',
        title: 'Terms of Use',
        path: '/some-path',
    },
];

const sections = [
    {
        id: 'sso',
        title: 'SSO (Single Sign On) Agreement',
        defaultExpanded: true,
    },
    {
        id: 'terms',
        title: 'Terms Of Use',
        defaultExpanded: true,
    },
];


export const FormComponent = () => (
    <Grid grow>
        <Grid.Col span={12}>
            <Tabs defaultValue={sections[0].title}>
                <Tabs.List>
                    {sections.map((item) => {
                                      return (
                                          <Tabs.Tab value={item.title} key={item.id}>
                                              {item.title}
                                          </Tabs.Tab>
                                      );
                                  }
                    )}
                </Tabs.List>
                {sections.map((item) => {
                                  return (
                                      <Tabs.Panel value={item.title} key={item.id}>
                                          <ScrollArea h={250} my={'lg'}>
                                              {item.id === 'sso' ? <SSOCustomerAgreement/> : <TermsOfUseDocument/>}
                                          </ScrollArea>
                                      </Tabs.Panel>
                                  );
                              }
                )}
            </Tabs>
        </Grid.Col>

        <Grid.Col>
            {agreements.map(agreement => {
                return (
                    <Field
                        my={'lg'}
                        key={agreement.id}
                        name={agreement.id}
                        label={`I agree to ${agreement.title}`}
                        component={PacFormikCheckbox}
                    />
                );
            })}

        </Grid.Col>
    </Grid>
);