import {useEffect} from 'react';

import {ReturnToSection} from '../components/ReturnToSection';
import {useApplications} from "../hooks/useApplications";
import {useSignInProfiles} from "../../sign-in-profiles/hooks/useSignInProfiles.ts";


export const IndexPage = () => {

    const {
        handleFetchSignInProfiles,
        activeProfile
    } = useSignInProfiles();
    const show_return_to = activeProfile?.showReturnTo;

    useEffect(() => {
        handleFetchSignInProfiles()
    }, [])


    const {
        applications,
        handleFetchApplications,
        handleSwapPositions
    } = useApplications();


    useEffect(() => {
        handleFetchApplications();
    }, []);


    if (show_return_to === null) {
        return <div>Loading</div>;
    }

    if (show_return_to === true && Array.isArray(applications)) {
        return (
            <ReturnToSection
                applications={applications}
                swapPositions={handleSwapPositions}
            />
        );
    } else {
        return null;
    }
};
