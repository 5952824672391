import {
    Field,
    FormikProps
} from 'formik';


import {Box} from '@mantine/core';

import {PacFormikInput} from '@pac/platform-ui-components';

import {I18n} from '@aws-amplify/core';
import {Account} from "../types.ts";


export const FormComponent = (props: FormikProps<Account>) => {

    return (
        <Box mt="sm">
            <Field
                autoFocus
                tabIndex={0}
                type="email"
                description={I18n.get('Enter your work email address, e.g. my.name@mycompany.com')}
                placeholder={I18n.get('Work Email Address')}
                name="email"
                id="email"
                onChange={props.handleChange}
                component={PacFormikInput}
            />
        </Box>
    );
};
