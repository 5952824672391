import {
    Field,
    FormikProps,
} from 'formik';

import {I18n} from '@aws-amplify/core';

import {
    Box,
    SimpleGrid
} from '@mantine/core';


import {
    PacFormikInput,
    PacFormikPasswordInput
} from "@pac/platform-ui-components";
import {Credentials} from "../types.ts";

export const FormComponent = ({
                                  values,
                              }: FormikProps<Credentials>) => {
    return (
        <SimpleGrid>
            <Box mt="sm">
                <Field
                    type="email"
                    name="email"
                    value={values.email}
                    placeholder={I18n.get('Email')}
                    component={PacFormikInput}
                    disabled={false}
                />
            </Box>

            <Box>
                <Field
                    autoFocus
                    tabIndex={0}
                    name="password"
                    placeholder={I18n.get('Password')}
                    component={PacFormikPasswordInput}
                />
            </Box>
        </SimpleGrid>
    );
};
