import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    deleteAllRecords,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/AccountsService';

import {
    fetchRecords as fetchUserRecords,
    fetchUserWithLocation,
} from '../../../features/users/services/UsersService';

import {
    deleteAllCase,
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';

import getUuid from 'uuid-by-string';


import {schema} from 'normalizr';
import {Account} from "../types.ts";
import {NEXTCognitoUser} from "../../../types";
import {moduleConfig} from "../config";

const entityKey = moduleConfig.entityKey;

export const entitySchema = new schema.Entity(entityKey);
export const arrayOfRecords = new schema.Array(entitySchema);


const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
    signOutResult: null,
} as {
    byId: { [key: string]: any },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string,
    signOutResult: any,
};

const convertRecord = (record: NEXTCognitoUser, emailAddress: string) => {
    let nextCloudIdentityProvider = null;

    if (
        typeof record['federated-id-providers'] !== 'undefined' &&
        Array.isArray(record['federated-id-providers']) &&
        record['federated-id-providers'].length > 0
    ) {
        nextCloudIdentityProvider = record['federated-id-providers'][0];
    }

    const recordId = getUuid(emailAddress);

    return Object.assign(
        {},
        {
            id: recordId,
            username: record['username'],
            cognitoUserPoolDomain: record['cognito-user-pool-domain'],
            appClientId: record['app-client-id'],
            description: record['description'],
            callbackUrls: record['callback-urls'],
            icaoCode: record['icao-code'],
            cognitoUserPoolId: record['cognito-user-pool-id'],
            federatedIdProviders: record['federated-id-providers'],
            firstName: record['first-name'],
            lastName: record['last-name'],
            emailAddress: emailAddress,
            nextcloudIdentityProvider: nextCloudIdentityProvider,
            isAuthorizing: 'no',
            isVisible: 'yes',
            photoUrl: record['photo-url'],
            name: record['name'],
            email: emailAddress,
        } as Account
    );
};

const normalizeRecord = (record: Account) => {
    return Object.assign({}, record, {
        id: getUuid(record.emailAddress as string),
    });
};

// genenrates pending, fulfilled and rejected
export const fetchAccounts = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (query?: { emailAddress?: string }) => {
        return fetchRecords(query?.emailAddress as string)
            .then((response) => {
                return response.map((record) => {
                    return normalizeRecord(record);
                });
            });
    }
);

export const fetchAccount = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                return normalizeRecord(response);
            });
    });

export const saveAccount = createAsyncThunk(
    `${entityKey}/add`,
    (payload: Account) => {
        return fetchRecords(payload.emailAddress as string)
            .then((response) => {
                if (Array.isArray(response) && response.length > 0) {
                    return fetch(response[0].id);
                }

                return add(payload)
                    .then((response) => normalizeRecord(response));
            });
    });


export const updateAccount = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record
     }: { id: string, record: Account }) => {
        return update(id, record)
            .then((response) => normalizeRecord(response));
    }
);

export const deleteAccount = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    }
);

export const deleteAllAccounts = createAsyncThunk(
    `${entityKey}/deleteAllRecords`,
    () => {
        return deleteAllRecords()
            .then((response) => response);
    }
);

export const searchAccounts = createAsyncThunk(
    `${entityKey}/search`,
    async (emailAddress: string) => {
        return fetchRecords(emailAddress)
            .then(
                async (response) => {

                    if (Array.isArray(response) && response.length > 0) {
                        const record = response[0];
                        const updated = Object.assign({}, record, {
                            isVisible: 'yes',
                        });
                        return update(record.id, updated)
                            .then(
                                () => updated
                            );
                    }

                    const location = await fetchUserLocation(emailAddress);
                    console.log('IN SEARCH ACCOUNT', location);

                    const user = await fetchUserWithLocation(location);
                    console.log('GOT USER INFO', user);

                    const payload = convertRecord(user, emailAddress);
                    console.log('CONVERTED TO ACCOUNT PAYLOAD', payload);

                    return add(payload)
                        .then(response => response);
                }
            );
    }
);

const fetchUserLocation = async (emailAddress: string) => {

    console.debug('FETCH USER LOCATION', emailAddress);
    const location = await fetchUserRecords({
                                                email_address: emailAddress,
                                            })
        .then(
            (response) => {

                const {
                    data,
                    status
                } = response;

                // received Location header
                if (
                    status === 302 &&
                    typeof data['location'] !== 'undefined' &&
                    data['location'] !== ''
                ) {
                    return data['location'];
                }
            }
        );

    return location;
};

const accountsSlice = createSlice({
                                      name: entityKey,
                                      initialState,
                                      reducers: {},
                                      extraReducers: (builder) => {
                                          // FETCH MANY
                                          fetchManyCase(builder, fetchAccounts, entityKey);

                                          // FETCH ONE
                                          fetchOneCase(builder, fetchAccount);

                                          // SAVE ONE
                                          saveOneCase(builder, saveAccount);

                                          // UPDATE ONE
                                          updateOneCase(builder, updateAccount);

                                          // DELETE ONE
                                          deleteOneCase(builder, deleteAccount);

                                          // DELETE ALL
                                          deleteAllCase(builder, deleteAllAccounts);

                                          // SEARCH WITH EMAIL
                                          saveOneCase(builder, searchAccounts);
                                      },
                                  });

export default accountsSlice.reducer;
