import {useEffect} from 'react';

import {ActionsControl} from './ActionsControl';
import {useSignInProfiles} from "../../sign-in-profiles/hooks/useSignInProfiles.ts";

export const SignInProfilesControl = () => {

    const {
        signInProfiles,
        handleFetchSignInProfiles,
        handleDeleteAllSignInProfiles
    } = useSignInProfiles();

    useEffect(() => {
        handleFetchSignInProfiles();
    }, []);

    return (
        <ActionsControl
            label="Sign-In Profiles"
            resourceCount={signInProfiles.length}
            onClickHandler={handleDeleteAllSignInProfiles}
        />
    );
};
