import {useEffect} from 'react';

import {Auth} from '@aws-amplify/auth';

import {useNavigate} from 'react-router-dom';

import {I18n} from '@aws-amplify/core';

import {ProfilesGrid} from '../components/ProfilesGrid';

import {IndexPage as ReturnToComponent} from '../../../features/applications/pages/IndexPage';

import {
    Stack,
    Title
} from '@mantine/core';

import {DisplayUserState} from '../../../components/DisplayUserState';
import {useAccounts} from "../hooks/useAccounts";
import {Account} from "../types.ts";


export const IndexPage = () => {

    const navigate = useNavigate();

    const {
        accounts,
        handleFetchAccounts,
        handleUpdateAccount,
        handleDeleteAccount
    } = useAccounts()

    useEffect(() => {
        handleFetchAccounts();
    }, []);


    const handleContinue = (account: Account) => {
        Auth.configure({
                           userPoolId: account.cognitoUserPoolId,
                           userPoolWebClientId: account.appClientId,
                       });


        Auth.currentAuthenticatedUser()
            .then(
                () => {
                    navigate(`/accounts/${account.id}/redirect`);
                },
                (error) => {
                    console.debug(
                        'LOGIN APP. ACCOUNTS INDEX PAGE. USER EXCEPTION: ',
                        error
                    );
                    navigate(`/accounts/${account.id}/method-selection`);
                }
            );
    };

    const handleSignOutAndForget = async (account: Account) => {
        // forget account
        handleDeleteAccount(account.id)

        // sign out
        Auth.configure({
                           userPoolId: account.cognitoUserPoolId,
                           userPoolWebClientId: account.appClientId,
                       });

        // @see this - https://github.com/aws-amplify/amplify-js/issues/3540
        try {
            await Auth.signOut({global: true});
        } catch (e) {
            await Auth.signOut();
        }

        return false;
    };

    const handleSignOut = async (account: Account) => {
        // sign out
        Auth.configure({
                           userPoolId: account.cognitoUserPoolId,
                           userPoolWebClientId: account.appClientId,
                       });

        try {
            await Auth.signOut({global: true});
        } catch (e) {
            await Auth.signOut();
        }
        return false;
    };

    const handleHideAccount = async (account: Account) => {
        const updated = Object.assign({}, account, {isVisible: 'no'});
        handleUpdateAccount(account.id, updated);
        return false;
    };


    return (
        <Stack spacing="xl">
            <Title order={3}>{I18n.get('Pick an account') + ':'}</Title>

            <ProfilesGrid
                accounts={accounts}
                handleContinue={handleContinue}
                handleSignOut={handleSignOut}
                handleSignOutAndForget={handleSignOutAndForget}
                handleHideAccount={handleHideAccount}
            />

            <ReturnToComponent/>

            {<DisplayUserState accounts={accounts} enabled={false}/>}
        </Stack>
    );
};
