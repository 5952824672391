import {I18n} from '@aws-amplify/core';

import {
    AiOutlineHome,
    AiOutlineInfoCircle,
    AiOutlineSetting
} from 'react-icons/ai';

import {NavLink} from 'react-router-dom';

import {Group} from '@mantine/core';

export const PageFooter = ({
                               homeLabel = 'Home',
                               homePath = '/',
                               aboutLabel = 'About',
                               aboutPath = '/about',
                               signInProfilesLabel = 'Sign-in Profiles',
                               signInProfilesPath = '/sign-in-profiles',
                           }: {
    homeLabel?: string,
    homePath?: string,
    aboutLabel?: string,
    aboutPath?: string,
    signInProfilesLabel?: string,
    signInProfilesPath?: string,
}) => {

    return (
        <Group position="center" mt="xl" spacing={'lg'}>
            <NavLink to={homePath}>
                <Group>
                    <AiOutlineHome/>
                    {I18n.get(homeLabel)}
                </Group>
            </NavLink>


            <NavLink to={aboutPath}>
                <Group spacing="xs">
                    <AiOutlineInfoCircle/>
                    {I18n.get(aboutLabel)}
                </Group>
            </NavLink>

            <NavLink to={signInProfilesPath}>
                <Group position="center" spacing="xs">
                    <AiOutlineSetting/>
                    {I18n.get(signInProfilesLabel)}
                </Group>
            </NavLink>
        </Group>
    )
};