import {
    Navigate,
    useParams
} from 'react-router-dom';
import {RecordForm} from '../forms/RecordForm';
import {notifySuccess} from "../../../components/ServiceResponseNotifications";
import {useServiceAgreement} from "../hooks/useServiceAgreement";
import {useAccount} from "../../accounts/hooks/useAccount";
import {UserAgreement} from "../types.ts";


export const AcceptancePage = () => {

    const {account: accountId} = useParams();

    const {
        record: account,
    } = useAccount(accountId);


    if (typeof account === 'undefined' || account === null) {
        return <Navigate to="/accounts"/>;
    }

    const {
        userAgreementResult,
        handleUpdate
    } = useServiceAgreement();


    if (
        typeof userAgreementResult !== 'undefined' &&
        userAgreementResult !== null && userAgreementResult !== ''
    ) {

        if (userAgreementResult === 'success') {
            notifySuccess('Terms of Use Accepted');
            return <Navigate to={`/accounts/${account.id}/user-agreements/acceptance-confirmation/`}/>;
        } else {
            return <Navigate to={`/accounts/${account.id}/user-agreements/acceptance-problem`}/>;
        }
    }

    let record = {
        sso: false,
        terms: false,
    } as UserAgreement;


    return (
        <RecordForm record={record} handleSubmit={handleUpdate}/>
    );
};

