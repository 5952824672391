import {UserAgreementsTable} from '../components/Table';

import {
    IndexPageLayout,
    PlatformLoader
} from '@pac/platform-ui-components';

import {Box} from '@mantine/core';

import {useAccount} from "../../accounts/hooks/useAccount";
import {useAuth} from "../../../hooks/useAuth.ts";
import {UserAgreement} from "../types.ts";
import {useParams} from 'react-router-dom';


export const IndexPage = () => {

    const {account: accountId} = useParams();

    const {
        record: account,
    } = useAccount(accountId);

    const {profile} = useAuth({account});

    if (typeof profile === 'undefined' || profile === null) {
        return (
            <Box>
                <PlatformLoader message="Working...Please wait"/>
            </Box>
        );
    }

    let formatted = new Date(profile.tc.timestamp * 1000);

    let agreements = [
        {
            name: 'Panasonic Avionics Corporation Terms of Use',
            viewPath: '/user-agreements/terms-of-use',
            acceptedBy: profile.name,
            acceptedOn: formatted.toLocaleDateString(),
            acceptedIpAddress: profile.tc['ip-address'],
        },
    ] as UserAgreement[];

    return (
        <IndexPageLayout title="Agreements">
            <UserAgreementsTable agreements={agreements}/>
        </IndexPageLayout>
    );
};
