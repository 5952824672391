import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';
import {Notifications} from '@mantine/notifications';
import {AccountsContextProvider} from "./features/accounts/context/AccountsContext.tsx";
import {ApplicationsContextProvider} from "./features/applications/context/ApplicationsContext.tsx";
import {ThemeProvider} from "./layout/ThemeProvider.tsx";
import {
    Suspense,
    useState
} from 'react';
import {
    ColorScheme,
    ColorSchemeProvider
} from '@mantine/core';
import {routesConfig} from "./config/routes.tsx";
import {PlatformLoader} from '@pac/platform-ui-components';


// var cookies = document.cookie.split(";");
// var cookies = document.cookie;


const App = () => {

    const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
    const toggleColorScheme = (value?: ColorScheme) =>
        setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

    const router = createBrowserRouter(routesConfig);

    return (
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <Suspense fallback={<PlatformLoader message={'Loading application...Please, wait!'}/>}>
                <ThemeProvider theme={{colorScheme}}>
                    <AccountsContextProvider>
                        <ApplicationsContextProvider>
                            <Notifications position="bottom-left" zIndex={2077}/>
                            <RouterProvider router={router}/>

                            {/*<Routes>*/}
                            {/*    <Route path="/" element={<AppLayout/>}>*/}
                            {/*        <Route*/}
                            {/*            path="login"*/}
                            {/*            element={<Navigate to="/redirect"/>}*/}
                            {/*        />*/}
                            {/*        <Route*/}
                            {/*            path="oauth2/authorize*"*/}
                            {/*            element={<AuthorizePage/>}*/}
                            {/*        />*/}

                            {/*        <Route*/}
                            {/*            path="accounts/*"*/}
                            {/*            element={<AccountsLayout/>}*/}
                            {/*        />*/}

                            {/*        <Route*/}
                            {/*            path="sign-in-profiles/*"*/}
                            {/*            element={<SignInProfilesLayout/>}*/}
                            {/*        />*/}

                            {/*        <Route path="/index.html" element={<IndexPage/>}/>*/}

                            {/*        <Route path="/" element={<IndexPage/>}/>*/}
                            {/*        <Route path="/about" element={<AboutPage/>}/>*/}
                            {/*        <Route path="/404" element={<PageNotFoundPage/>}/>*/}
                            {/*        <Route path="/offline" element={<OfflinePage/>}/>*/}
                            {/*        <Route path="*" element={<PageNotFoundPage/>}/>*/}
                            {/*    </Route>*/}

                            {/*</Routes>*/}
                        </ApplicationsContextProvider>
                    </AccountsContextProvider>
                </ThemeProvider>
            </Suspense>
        </ColorSchemeProvider>
    )
};

// Hub.listen(/.*/, (hubData) => {
//     const { event, data } = hubData.payload;
//     console.debug('EVENT', event);
//     console.debug('DATA', data);

//     if (event === 'networkStatus') {
//         console.debug(`User has a network connection: ${data.active}`);
//     }
// });

export default App;