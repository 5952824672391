import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {fetch} from '../services/CustomersService';

import {schema} from 'normalizr';
import {Customer} from "../types.ts";
import {RootState} from "../../../store";

const entityKey = 'customers';


export const entitySchema = new schema.Entity(entityKey);
export const arrayOfRecords = new schema.Array(entitySchema);

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as {
    byId: { [key: string]: any },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string,
};

export const fetchCustomer = createAsyncThunk<Customer, string>(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => response);
    });

const customersSlice = createSlice({
                                       name: entityKey,
                                       initialState,
                                       reducers: {},
                                       extraReducers: (builder) => {
                                           // FETCH ONE
                                           builder.addCase(fetchCustomer.pending, (state: RootState) => {
                                               state.loading = true;
                                           });

                                           builder.addCase(fetchCustomer.fulfilled, (state: RootState, action) => {
                                               state.byId[action.payload.id] = action.payload;
                                               state.loading = false;
                                           });

                                           builder.addCase(fetchCustomer.rejected, (state: RootState, action) => {
                                               state.loading = false;
                                               state.error = action.error.message;
                                           });
                                       },
                                   });

export default customersSlice.reducer;
