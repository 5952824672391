import {I18n} from '@aws-amplify/core';
import {Cache} from '@aws-amplify/cache';

import {
    Box,
    Center,
    Group,
    Stack,
    Title
} from '@mantine/core';
import {
    NextButton,
    PlatformLoader
} from '@pac/platform-ui-components';
import {AiOutlineArrowRight} from 'react-icons/ai';
import {
    useEffect,
    useState
} from 'react';

const CancelLink = () => (
    <NextButton
        variant={'outline'}
        rightIcon={<AiOutlineArrowRight/>}
        onClick={() => {
            Cache.setItem('pac-authorizing', 'no');
            window.location.assign('/accounts');
        }}
    >
        {I18n.get('Start Over')}
    </NextButton>
);

const label = ['Authorizing', 'Please wait']
    .map((item) => I18n.get(item))
    .join('...');

export const AuthorizingIndicator = () => {

    const [showCancelLink, setShowCancelLink] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setShowCancelLink(true)
        }, 3000)
    }, [])

    return (
        <Stack>
            <Box my={'xl'}>
                <PlatformLoader message={label}/>
            </Box>

            {showCancelLink ? <Center>
                <Group>
                    <Title order={5}>Cancelled authentication? </Title>
                    <CancelLink/>
                </Group>
            </Center> : null}
        </Stack>
    );
};

