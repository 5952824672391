import {
    Formik,
    FormikProps
} from 'formik';

import {FormComponent} from './FormComponent';

import ValidationSchema from './ValidationSchema';

import {
    FormWithActionBar,
    PacFormikComponentState
} from '@pac/platform-ui-components';
import {UserAgreement} from "../types.ts";

export const RecordForm = ({
                               record,
                               handleSubmit
                           }: {
    record?: UserAgreement,
    handleSubmit: (values: UserAgreement) => void
}) => {

    const onSubmit = (values: UserAgreement) => {
        return handleSubmit(values);
    };


    const renderForm = (props: FormikProps<UserAgreement>) => {
        return (
            <FormWithActionBar handleSubmit={props.handleSubmit}>
                <FormComponent/>
                <PacFormikComponentState enabled={false} {...props} />
            </FormWithActionBar>
        );
    };

    let initialValues = {} as UserAgreement;

    if (record) {
        initialValues = record;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={onSubmit}
        >
            {renderForm}
        </Formik>
    );
};