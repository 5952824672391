import {Link} from 'react-router-dom';

import {
    Breadcrumbs,
    NavLink
} from '@mantine/core';


import {IndexPageLayout} from '@pac/platform-ui-components';
import {I18n} from '@aws-amplify/core';
import {TermsOfUseDocument} from "../components/TermsOfUseDocument.tsx";

export const ViewTermsOfUsePage = () => {
    return (
        <IndexPageLayout title={I18n.get('User Agreements')}>
            <Breadcrumbs>
                <NavLink
                    component={Link}
                    to="/user-agreements/list"
                    label="User Agreements"
                />

                {I18n.get('Terms Of Use')}
            </Breadcrumbs>

            <TermsOfUseDocument/>
        </IndexPageLayout>
    );
};
