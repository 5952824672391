import {NavLink} from 'react-router-dom';

import {I18n} from '@aws-amplify/core';
import {Account} from "../../accounts/types.ts";
import {Grid} from "@mantine/core";

export const FooterRow = ({account}: { account: Account }) => {
    const links = [
        {
            label: I18n.get('Forgot password') + '?',
            path: `/accounts/${account.id}/reset-password/selection`,
        },
    ];

    return (
        <Grid>
            <Grid.Col span={12}>
                {links.map((link, index) => (
                    <NavLink to={link.path} key={index}>
                        <p className="navigation-link">{link.label}</p>
                    </NavLink>
                ))}
            </Grid.Col>
        </Grid>
    );
};

