import {
    Navigate,
    useParams
} from 'react-router-dom';

import {ConfirmForm} from '../forms/ConfirmForm';

import {
    Box,
    Stack,
    Title
} from '@mantine/core';
import {useAccount} from "../../accounts/hooks/useAccount.ts";
import {useResetPassword} from "../hooks/useResetPassword.ts";
import {Credentials} from "../../credentials/types.ts";

export const ConfirmPage = () => {

    const {account: accountId} = useParams();

    const {
        record: account,
    } = useAccount(accountId);

    const {
        confirmChange,
        confirmResult
    } = useResetPassword();


    const handleSubmit = (values: Credentials) => {
        confirmChange(
            account,
            values
        );
        return;
    };

    if (typeof account === 'undefined' || account === null) {
        return <Navigate to="/404"/>;
    }

    if (confirmResult === 'success') {
        return <Navigate to={`/accounts/${account.id}/credentials`}/>;
    }

    const record = {} as Credentials;

    return (
        <Stack my={'lg'}>
            <Title order={3} tt={'uppercase'}>Enter Verification Code</Title>
            <Box mt="xl">
                {' '}
                We have sent a password reset code to your registered email
                address. Enter it below to reset your password.
            </Box>
            <ConfirmForm record={record} handleSubmit={handleSubmit}/>
        </Stack>
    );
};
