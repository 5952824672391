// import html from './sso-customer-agreement.html';


export const SSOCustomerAgreement = () => {

    return (
        <div>
            <b>AGREEMENT</b>
            <p>
                This
                agreement (this &quot;<b>Agreement</b>&quot;)
                contains the terms and conditions upon which Panasonic Avionics
                Corporation, a Delaware corporation (&quot;<b>PAC</b>&quot;),
                provides access to applications (including but not limited to DARTce,
                MyIFE, KONOS, Hanger, and Unity, and any updates and/or upgrades,
                collectively, the <b>&ldquo;Applications&rdquo;</b>)
                through the Single Sign-On Portal (the &quot;<b>SSO</b>&quot;).
                PAC owns and operates the SSO and Applications, and the SSO is hosted
                on one or more servers to provide online access. PAC operates the SSO
                to permit airline customers and/or their agents (&ldquo;<b>Users</b>&rdquo;)
                to access multiple Applications related to PAC in-flight
                entertainment and communications systems and/or services (&quot;<b>IFEC</b>&quot;
                systems) utilizing a single username and password.</p>
            <p>Read
                this Agreement carefully. By clicking &quot;I AGREE,&quot; you
                acknowledge that you have read and accept the terms and conditions of
                this Agreement in their entirety
            </p>
            <p><b>SECTION
                1: OBLIGATIONS AND REPRESENTATIONS</b></p>
            <p><b>1.1.</b></p>
            <p>
                <b>ONLY
                    EMPLOYEES OF USERS WITH AUTHORITY TO BIND THOSE USERS MAY ACCEPT THIS
                    AGREEMENT. THE TERM &quot;YOU&quot; OR &quot;YOUR&quot; IN THIS
                    AGREEMENT INCLUDES YOUR EMPLOYER, AND YOU REPRESENT TO PAC THAT YOU
                    ARE AUTHORIZED TO ACCEPT THIS AGREEMENT ON BEHALF OF THAT USER.</b>
            </p>
            <p><b>1.2.</b></p>
            <p>
                Upon
                agreeing to the terms and conditions of this Agreement, PAC shall
                permit you to access the SSO and utilize the Applications related to
                the PAC IFEC systems.</p>
            <p><b>SECTION
                2: PURPOSE, RESTRICTIONS AND RESERVATIONS</b></p>
            <p><b>2.1.</b></p>
            <p>
                Access
                to the SSO and Applications is provided to you solely to enable you
                to obtain information related to PAC IFEC systems (the &quot;<b>Purpose</b>&quot;).
                For the Purpose, PAC has assigned or will assign you a username and
                password, which you shall keep confidential and may use to access and
                use the SSO and Applications. You shall use the SSO and Applications
                solely for the Purpose. You shall not, without PAC&rsquo;s prior
                written approval, use or allow any other person to use the SSO or
                Applications for any other reason.</p>
            <p><b>2.2.</b></p>
            <p>
                This
                Agreement only conveys permission for you to use the SSO and
                Applications as provided in this Agreement. PAC may revoke this
                permission at any time. You will not receive, and you shall not
                assert, any right, title, or interest in or to the SSO and/or the
                Applications.</p>
            <p><b>2.3.</b></p>
            <p>
                PAC
                reserves to itself all rights and benefits in the SSO and
                Applications afforded under applicable laws and conventions.</p>
            <p><b>2.4.</b></p>
            <p>
                With
                respect to any use of the SSO and Applications under this Agreement,
                you shall employ security and confidentiality measures that are
                consistent with the practices and procedures under which you protect
                your own most valuable proprietary information and materials from
                unauthorized or unlawful use. The measures required under this
                Section 2.4 will in no event be less than that which is reasonable
                under the circumstances.</p>
            <p><b>2.5.</b></p>
            <p>
                While
                using the SSO and Applications you shall not:</p>
            <ul>
                <li>
                    <p>
                        take
                        any action that may adversely affect the operation of any IFEC
                        system, the SSO, and/or Applications;</p></li>
                <li>
                    <p>transfer
                        your username or password to another person without PAC&apos;s written
                        consent;</p></li>
                <li>
                    <p>violate
                        any laws applicable to your use of the SSO and/or Applications;</p></li>
                <li>
                    <p>distribute
                        viruses or any other technologies that may harm the server or
                        servers that host the SSO and Applications;</p></li>
                <li>
                    <p>interfere,
                        or attempt to interfere, with the proper working of the SSO or any
                        Application;</p></li>
                <li>
                    <p>modify,
                        translate, reverse engineer, decompile, disassemble, or create
                        derivative works based on the SSO, Applications, or any portion
                        thereo; or</p></li>
                <li>
                    <p>any
                        combination of the above.</p></li>
            </ul>
            <p><b>SECTION
                3: TERM AND TERMINATION</b></p>
            <p><b>3.1.</b></p>
            <p>
                This
                Agreement will commence upon your acceptance of this Agreement. You
                may terminate this Agreement at any time by written notice to PAC.
                PAC may terminate this Agreement at any time by revoking your access
                to the SSO and Applications.</p>
            <p><b>3.2.</b></p>
            <p>
                Your
                permission to use the SSO and Applications will terminate
                automatically if you fail to comply with any provision of this
                Agreement. No notice will be required from PAC to give effect to
                termination for this reason.</p>
            <p>


            </p>
            <p><b>SECTION
                4: NO WARRANTIES</b></p>
            <p><b>4.1.</b></p>
            <p>
                <b>ACCESS
                    TO THE SSO AND APPLICATIONS WILL BE PROVIDED ON AN AS-IS,
                    AS-AVAILABLE BASIS. NEITHER PAC NOR ITS AFFILIATES, SUPPLIERS,
                    LICENSEES, EMPLOYEE OR AGENTS WARRANT THAT THE SSO OR APPLICATIONS
                    WILL (I) MEET YOUR REQUIREMENTS OR (II) BE AVAILABLE ON AN
                    UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS. PAC WILL NOT BE
                    LIABLE TO YOU OR ANY OTHER USER FOR WARRANTIES OF ANY KIND WITH
                    RESPECT TO THE SSO AND APPLICATIONS, WHETHER EXPRESS, IMPLIED,
                    STATUTORY, ORAL OR WRITTEN, INCLUDING ANY IMPLIED WARRANTIES OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT,
                    TITLE, OR ANY IMPLIED WARRANTY ARISING BY USAGE OF TRADE, COURSE OF
                    DEALING OR COURSE OF PERFORMANCE.</b>
            </p>
            <p><b>4.2.</b></p>
            <p>
                <b>PAC
                    WILL NOT BE LIABLE TO YOU OR ANY OTHER USER FOR ANY SPECIAL,
                    INDIRECT, INCIDENTAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE DAMAGES
                    ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WORK
                    DELAYS, LOST GOODWILL, PROFIT, REVENUE OR SAVINGS, LOSS OF USE, COST
                    OF CAPITAL, COST OF SUBSTITUTE EQUIPMENT, FACILITIES OR DOWNTIME
                    COSTS, EVEN IF PANASONIC HAS BEEN ADVISED OR IS AWARE OF THE
                    POSSIBILITY OF THOSE DAMAGES.</b>
            </p>
            <p><b>SECTION
                5: CONFIDENTIALITY</b></p>
            <p>
                You
                shall not disclose to any person, other than employees of your
                organization who have a need to know, any information that PAC
                discloses to you that you should reasonably understand to be
                confidential or proprietary, based on the nature of the information
                and the circumstances of its disclosure. Nothing in this Section 5 is
                intended to replace or modify any separate confidentiality agreement
                that may exist between the User and PAC.</p>
            <p><b>SECTION
                6: INDEMNITY</b></p>
            <p>
                You
                shall defend and indemnify PAC (and its officers, directors,
                employees, agents, affiliated companies, suppliers, and customers),
                from and against all claims, demands, damages, and costs including
                reasonable attorneys&apos; fees, made by any non-party to this Agreement
                arising out of your breach of this Agreement.</p>
            <p><b>SECTION
                7: MISCELLANEOUS</b></p>
            <p><b>7.1.</b></p>
            <p>
                You
                shall not assign your rights or transfer your obligations under this
                Agreement without PAC&rsquo;s prior written consent. Any assignment
                that violates this Section 7.1 will be void and of no effect.</p>
            <p><b>7.2.</b></p>
            <p>
                PAC
                will control and operate access to the SSO and Applications primarily
                from within the United States of America. This Agreement will be
                governed by the laws of the United States and the state of New York,
                without giving effect to any conflict-of-laws principles that may
                provide the application of the law of another jurisdiction. Any
                action arising out of or relating to this Agreement will be brought
                in the United States District Court for the Central District of
                California, or if that court lacks subject-matter jurisdiction, then
                in the state courts of Orange County California. Each party hereby
                waives any objection they may have to personal jurisdiction in these
                courts.</p>
            <p><b>7.3.</b></p>
            <p>
                PAC
                may modify this Agreement by providing you with a copy of this
                Agreement as modified. Providing you with a copy of the modified
                version of this Agreement will terminate the existing version of this
                Agreement, along with your access to the SSO and Applications, until
                you have accepted the modified version of this Agreement. Except for
                the foregoing, this Agreement may not be modified except by mutual
                written agreement between you and PAC that is signed by authorized
                representatives of both parties and expressly references amendment of
                this Agreement. You acknowledge that no other written, oral or
                electronic communications will serve to modify or supplement this
                Agreement, and you shall not make any claims that are inconsistent
                with this understanding or in reliance on communications that are not
                part of this Agreement.</p>
            <p>
                The
                section headings used in this Agreement are for convenience only and
                will not affect this Agreement&rsquo;s interpretation.
            </p>
            <p>
                Except
                for any separate confidentiality agreement that may exist between the
                User and PAC, this Agreement constitutes the entire agreement between
                the parties with respect to the subject matter of this Agreement and
                supersedes any prior agreements, whether oral or written, with
                respect to that subject matter.</p>
            <p>
                If
                either party disputes the enforceability of any provision of this
                Agreement, the parties request that the court interpret this
                Agreement, consistent with the parties&rsquo; original intent, as
                follows: (a) by holding the entire Agreement unenforceable if
                modifying or disregarding a provision results in the failure of any
                essential purpose of this Agreement; or (b) by modifying or
                interpreting that provision to make it enforceable or, by
                disregarding the provision and keeping the remainder of this
                Agreement in effect if the law does not permit modification.</p>
            <p><b>7.4.</b></p>
            <p>
                The
                use of the singular form in this Agreement will include the plural
                form, and the use of the plural form will include the singular form.</p>
            <p><b>7.5.</b></p>
            <p>
                The
                parties do not intend for anything in this Agreement to confer any
                third-party beneficiary rights on any person.</p>
            <p><b>7.6.</b></p>
            <p>
                No
                agency, partnership, joint venture, employee-employer, or
                franchiser-franchisee relationship is intended or created by this
                Agreement.</p>
            <p><b>SECTION
                8: SURVIVAL</b></p>
            <p>
                The
                provisions of this Agreement that by their nature should survive
                termination will survive expiration or termination of this
                Agreement, including provisions regarding defense and indemnity,
                limitations on liability, choice of law and venue, and jurisdiction.</p>
        </div>
    )


}
