import {useEffect} from 'react';

import {
    Hub,
    HubPayload
} from '@aws-amplify/core';
import {Cache} from '@aws-amplify/cache';

import {
    Navigate,
    useNavigate
} from 'react-router-dom';

import {
    getWithIdTokenPayload,
    update as updateAccount
} from '../../accounts/services/AccountsService';
import {AuthorizingIndicator} from "../components/AuthorizingIndicator";
import {useAccounts} from "../../accounts/hooks/useAccounts";


export const IndexPage = () => {
    const navigate = useNavigate();
    const {handleClearSelection} = useAccounts();

    useEffect(() => {
        handleClearSelection();
    }, []);


    const hubListenerCancelToken = Hub.listen('auth', (data) => {
        const {payload} = data;
        onAuthEvent(payload);
    });


    const onAuthEvent = (payload: HubPayload) => {

        switch (payload.event) {
            case 'signIn':
                handleSignInEvent(payload)
                break;
            case 'cognitoHostedUI':
                break;
            case 'signIn_failure':
                break;
            case 'cognitoHostedUI_failure':
                break;
        }
    };

    const handleSignInEvent = async (payload: HubPayload) => {

        hubListenerCancelToken()

        console.debug('LOGIN APP. INDEX PAGE. EVENT', payload.event);

        if (payload.data.signInUserSession === null) {
            return;
        }

        console.log(
            'LOGIN APP. INDEX PAGE. PAYLOAD',
            payload.data
        );

        let idTokenPayload =
            payload.data.signInUserSession.idToken.payload;
        console.log(
            'LOGIN APP. INDEX PAGE. ID TOKEN PAYLOAD',
            idTokenPayload
        );

        const testing = false;
        if (testing) {
            idTokenPayload = {
                'email': '122555@panasonic.aero',
                'identities': [
                    {
                        'userId': '122555@panasonic.aero',
                        'providerName': 'panasonic',
                        'providerType': 'email'
                    }
                ]
            }

        }


        console.debug('FIND ACCOUNT WITH TOKEN PAYLOAD', idTokenPayload)

        const account = await getWithIdTokenPayload(idTokenPayload)
        console.debug('FOUND ACCOUNT IN HOME PAGE', account)
        if (account) {
            await updateAccount(
                account.id,
                Object.assign({}, account, {
                    isAuthorizing: 'no',
                })
            );
            navigate(`/accounts/${account.id}/redirect`)
        } else {
            let email = idTokenPayload['email'];
            return <Navigate to={`/accounts/not-found/${email}`}/>;
        }

    }


    // console.debug('LOGIN APP. INDEX PAGE. EVENT - canceling the listener');
    // hubListenerCancelToken(); // stop listening for messages

    let authorizing = Cache.getItem('pac-authorizing');

    if (authorizing === 'yes') {
        return <AuthorizingIndicator/>;
    } else {
        return <Navigate to="/accounts"/>;
    }


};
