import {AppLayout} from "../layout/AppLayout.tsx";
import {AuthorizePage} from "../features/oauth2/pages/AuthorizePage.tsx";
import {Navigate} from "react-router-dom";
import {IndexPage} from "../features/home/pages/IndexPage.tsx";
import {PageNotFoundPage} from "../features/home/pages/PageNotFoundPage.tsx";
import {OfflinePage} from "../features/home/pages/OfflinePage.tsx";
import {AccountsLayout} from "../features/accounts/layout.tsx";
import SignInProfilesLayout from "../features/sign-in-profiles/layout.tsx";
import {IndexPage as AboutPage} from "../features/about/pages/IndexPage.tsx";

export const routesConfig = [{
    path: '/',
    element: <AppLayout/>,
    children: [{
        index: true,
        element: <IndexPage/>,
    }, {
        path: '/login',
        element: <Navigate to="/redirect"/>,
    }, {
        path: '/accounts/*',
        element: <AccountsLayout/>,
    }, {
        path: '/sign-in-profiles/*',
        element: <SignInProfilesLayout/>,
    }, {
        path: '/oauth2/authorize*',
        element: <AuthorizePage/>,
    }, {
        path: '/about',
        element: <AboutPage/>,
    }, {
        path: '/offline',
        element: <OfflinePage/>,
    }, {
        path: '404',
        element: <PageNotFoundPage/>,
    }, {
        path: '*',
        element: <PageNotFoundPage/>,
    }],
}]