import {I18n} from '@aws-amplify/core';

import {
    Box,
    Center
} from '@mantine/core';
import {NextCloseButton} from "@pac/platform-ui-components";

export const ActionsControl = ({
                                   label,
                                   resourceCount,
                                   onClickHandler
                               }: {
    label: string,
    resourceCount: number,
    onClickHandler: () => void
}) => (
    <tr>
        <td>
            <Box mt="xl">
                {I18n.get(label)}:
            </Box>
        </td>
        <td>
            <Box mt="xl">
                <Center>{resourceCount}</Center>
            </Box>
        </td>

        <td>
            <Center mt="sm">
                {resourceCount > 0 ? (
                    <NextCloseButton
                        onClick={onClickHandler}
                    >
                        {I18n.get('Clear')}
                    </NextCloseButton>
                ) : null}
            </Center>
        </td>
    </tr>
);