import {I18n} from '@aws-amplify/core';

import {
    Box,
    SimpleGrid,
    Title
} from '@mantine/core';

export const HeadingRow = ({
                               title = 'Sign In',
                               subtitle = '',
                               text = ''
                           }: {
    title?: string,
    subtitle?: string,
    text?: string
}) => {
    return (
        <SimpleGrid>
            <Title order={3}>{I18n.get(title)}</Title>
            <Box>{I18n.get(subtitle)}</Box>
            <Box>{I18n.get(text)}</Box>
        </SimpleGrid>
    );
};
