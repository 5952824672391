import {
    Formik,
    FormikProps
} from 'formik';

import {EmailFormValidationSchema} from './EmailFormValidationSchema.ts';


import {
    ActionBar,
    FormWithActionBar,
    NextSubmitButton,
    PacFormikComponentState
} from '@pac/platform-ui-components';
import {Account} from "../types.ts";
import {FormComponent} from "./FormComponent.tsx";


export const EmailForm = ({
                              record,
                              handleSubmit
                          }: {
    record: Account,
    handleSubmit: (record: Account) => void,
}) => {
    // const renderForm = (props: FormikProps<Account>) => (
    //     <>
    //         <FormComponent {...props} />
    //         <PacFormikComponentState enabled={false} {...props} />
    //     </>
    // );

    const renderForm = (props: FormikProps<Account>) => (
        <FormWithActionBar
            handleSubmit={props.handleSubmit}
            actionBar={<ActionBar primaryAction={<NextSubmitButton/>}/>}
        >
            <FormComponent {...props} />
            <PacFormikComponentState enabled={false} {...props} />
        </FormWithActionBar>
    );


    return (
        <Formik
            initialValues={record}
            validationSchema={EmailFormValidationSchema}
            onSubmit={handleSubmit}
        >
            {renderForm}
        </Formik>
    );
};
