import {Account} from "../features/accounts/types.ts";


export const DisplayUserState = (props: { accounts: Account[], enabled: boolean }) => {

    if (!props.enabled) {
        return null;
    }
    
    return (
        <div style={{margin: '1rem 0'}}>
            <pre
                style={{
                    background: '#e6f8fa',
                    fontSize: '1.2rem',
                    padding: '.5rem',
                }}
            >
              <strong>user props</strong> ={' '}

                {JSON.stringify(props, null, 2)}

            </pre>
        </div>
    );

}


