import {I18n} from '@aws-amplify/core';
import {
    Box,
    Center,
    Grid,
    Paper
} from '@mantine/core';

import {AiOutlineClose} from 'react-icons/ai';
import {NextButton} from '@pac/platform-ui-components';
import {Application} from "../types.ts";
import {useApplications} from "../hooks/useApplications.ts";


export const ApplicationsList = ({
                                     applications,
                                 }: {
    applications: Application[],
}) => {

    const {
        handleDeleteApplication,
        handleFetchApplications,
    } = useApplications();

    const reducedApplications = applications.filter((_, index) => index === 0);

    return (
        <Box>
            {reducedApplications.map((application, index) => {
                const className = index === 0 ? 'active-profile' : '';

                return (
                    <Paper key={index} className={className} shadow={'sm'}>
                        <Box py="sm">
                            <Grid>
                                <Grid.Col xs={12} sm={10}>
                                    <Box px="sm" mt="sm">
                                        {application.origin}
                                    </Box>
                                </Grid.Col>

                                <Grid.Col xs={12} sm={2}>
                                    <Center mt="xs">
                                        {!application.isDefault ? <NextButton
                                                variant={'outline'}
                                                leftIcon={<AiOutlineClose/>}
                                                onClick={() => {
                                                    console.debug('delete record', application);
                                                    handleDeleteApplication(application.id)
                                                    handleFetchApplications()
                                                }}
                                            >{I18n.get('Clear')}</NextButton> :
                                            <NextButton disabled={true}>Default</NextButton>}
                                    </Center>
                                </Grid.Col>
                            </Grid>
                        </Box>
                    </Paper>
                );
            })}
        </Box>
    );
};
