import {CognitoUserSession} from 'amazon-cognito-identity-js';
import {Application} from "../features/applications/types.ts";
import portalAppUrl from "../config/portal.ts";

class RedirectHelper {
    getRedirectUrlWithUserSession(userSession: CognitoUserSession) {
        if (typeof userSession === 'undefined') {
            return null;
        }

        const baseUrl = portalAppUrl;
        const urlHash = this.getUrlHashWithUserSession(userSession);

        return [baseUrl, urlHash].join('#');
    }

    getBaseUrl() {
        return portalAppUrl;
    }

    getUrlHashWithUserSession(userSession: CognitoUserSession) {
        if (typeof userSession === 'undefined') {
            return null;
        }


        const urlHash = Object.entries({
                                           id_token: userSession.getIdToken()
                                                                .getJwtToken(),
                                           access_token: userSession.getAccessToken()
                                                                    .getJwtToken(),
                                           refresh_token: userSession.getRefreshToken()
                                                                     .getToken(),
                                       })
                              .map(
                                  ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
                              )
                              .join('&');

        return urlHash;
    }
}

const redirectHelper = new RedirectHelper();

export const useRedirectUrl = ({
                                   application,
                                   session
                               }: {
    application: Application,
    session: CognitoUserSession
}) => {
    if (typeof application === 'undefined') {
        throw new Error('application is required parameter');
    }

    if (
        !Object.prototype.hasOwnProperty.call(application, 'origin') ||
        !Object.prototype.hasOwnProperty.call(application, 'pathname')
    ) {
        throw new Error(
            'application origin and pathname are required parameters' +
            JSON.stringify(application)
        );
    }

    if (typeof application.origin === 'undefined') {
        throw new Error('application origin is required parameter');
    }

    // baseUrl looks like this - http://myapp.nextcloud.aero/my-path;
    let baseUrl = application.origin + application.pathname;

    const enableDeepLinkingRedirect = true

    if (enableDeepLinkingRedirect && Object.prototype.hasOwnProperty.call(application, 'returnToSearch')) {
        if (application.returnToSearch?.length === 0)
            baseUrl = application.origin + application.pathname
        else
            // baseUrl looks like this - http://myapp.nextcloud.aero/my-path?foo=bar&zet=zero;
            baseUrl = application.origin + application.pathname + '?' + application.returnToSearch
    }


    const urlHash = redirectHelper.getUrlHashWithUserSession(session);
    const redirectUrl = [baseUrl, urlHash].join('#');

    return redirectUrl;
};
