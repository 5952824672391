import {
    Navigate,
    useParams
} from 'react-router-dom';

import {I18n} from '@aws-amplify/core';

import {CredentialsForm} from '../forms/CredentialsForm';

import {HeadingRow} from '../components/HeadingRow';

import {FooterRow} from '../components/FooterRow';
import {useAccount} from "../../accounts/hooks/useAccount";
import {useCredentials} from "../hooks/useCredentials";


export const IndexPage = () => {

    const {account: accountId} = useParams();

    const {
        record: account,
    } = useAccount(accountId);

    const {
        forcePasswordChange,
        handleSignIn,
    } = useCredentials();

    if (typeof account === 'undefined' || account === null) {
        return <Navigate to="/404"/>;
    }

    if (forcePasswordChange === true) {
        return <Navigate to={`/accounts/${account.id}/change-password`}/>;
    }

    return (
        <div>
            <HeadingRow title={I18n.get('Sign In')}/>
            <CredentialsForm
                handleSubmit={handleSignIn}
                emailAddress={account['emailAddress'] as string}
            />
            <FooterRow account={account}/>
        </div>
    );
};
