import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

import "@fontsource/inter"
import './assets/index.scss';

import './init/amplify';
import './init/app-metadata';
import './init/indexed-db';
import './init/i18n';
import './init/cloudwatch-rum';
import './init/offline-check';
import './init/sign-in-profiles';
import './init/applications';

import {Provider} from 'react-redux';
import {store} from "./store";


ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
        .render(
            <React.StrictMode>
                <Provider store={store}>
                    <App/>
                </Provider>
            </React.StrictMode>,
        )
