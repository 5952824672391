import {
    Center,
    Grid,
    Paper,
    Stack,
    useMantineTheme
} from '@mantine/core';

import {SmallProfileOverflowMenu} from './SmallProfileOverflowMenu';

import {UserAvatar} from './UserAvatar';
import {UserFullName} from './UserFullName';
import {useMediaQuery} from '@mantine/hooks';
import {Account} from "../types.ts";

export const SmallProfile = ({
                                 account,
                                 onClick,
                                 onSignOut,
                                 onSignOutAndForget,
                                 onHideAccount,
                             }: {
    account: Account,
    onClick: () => void,
    onSignOut: () => void,
    onSignOutAndForget: () => void,
    onHideAccount: () => void,
}) => {
    const theme = useMantineTheme();

    const lessThanMedium = useMediaQuery(
        `(max-width: ${theme.breakpoints.md}px)`
    );


    return (
        <Paper className="cursor-pointer" shadow={'sm'} withBorder>
            <Grid gutter="sm" py="sm">
                <Grid.Col
                    sm={12}
                    md={3}
                    onClick={onClick}
                    className="cursor-pointer"
                >
                    <Center>
                        <UserAvatar user={account} width="50" height="50"/>
                    </Center>
                </Grid.Col>

                <Grid.Col
                    xs={10}
                    md={7}
                    onClick={onClick}
                    className="cursor-pointer"
                >
                    <Stack
                        spacing="0px"
                        mt="sm"
                        align={lessThanMedium ? 'center' : 'left'}
                    >
                        <UserFullName user={account}/>
                        {account['emailAddress']}
                    </Stack>
                </Grid.Col>

                <Grid.Col xs={2} md={2}>
                    <Center mt="md">
                        <SmallProfileOverflowMenu
                            onSignOut={onSignOut}
                            onSignOutAndForget={onSignOutAndForget}
                            onHideAccount={onHideAccount}
                        />
                    </Center>
                </Grid.Col>
            </Grid>
        </Paper>
    );
};
